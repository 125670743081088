html body main {
    height: 100% !important;
    background-color: black !important;
}

.help {
    text-align: left !important;
    font-size: 1.1em;
    position : absolute;
    left : 0;
    margin: 2%;
    z-index: 5000;
}

.infocode {
    color:white;
    z-index: 5000;
    margin-left: 5%;
    margin-right: 5%;
}

a {
    color:white !important;
    text-decoration: underline !important;
}