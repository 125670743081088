body {
  background-color: black !important;
}

.bgWhite {
  background-color: #eee !important;
}
.elegant-color {
  background-color: #111111 !important;
}

.ui-center {
    text-align: center;
  }
  
  h1 {
    margin: 1.5em;
    font-size: 24px;
    text-align: center;
  }
  
  .carousel-container {
    position: relative;
    height: 350px;
    max-width: 1600px;
    margin: 0 auto;
    overflow: hidden;
    touch-action: pan-y;
    background-color: #222;
  }
  
  .carousel-track {
    display: flex;
    height: 100%;
  }
  
  .image-card {
    /* border: 2px solid white; */
  }

  .carousel-card {
    flex: 0 0 300px;
    height: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    

    -webkit-tap-highlight-color: transparent;
  }
  
  .carousel-card-inner {
    position: relative;
    flex-direction: column;
    width: 300px;
    height: 300px;
    border-radius: 5px;
  }
  
  .carousel-title {
    margin-top: -150px;
    font-size: 1.5em;
    text-align: center;
  }
  
  .carousel-text {
    padding: 1em;
    font-size: 14px;
    white-space: pre-wrap;
    text-align: left;
    font-family: sans-serif;
  }