.mdb-progress {
    box-shadow: none;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 4px;
    overflow: hidden;
    background-color: #eeeeee;
    
}

progress::-webkit-progress-bar {
    background-color: #eeeeee;
    
}

progress::-webkit-progress-value {
    background-color: orangered;
}

.progress .progress-bar {
    border-radius: 0;
    height: 4px;
    background-color: #C0C0C0; }

.dwnprogress {
    height:27px !important;
    float:left;
    color:rgba(247,244,228,0.5);
    font-size: 0.8em;

}

.butalbum {
    margin:1rem;
    height: 27px;
    /* font-size: 0.64rem; */
    /* text-transform: uppercase; */
    background-color:#4285f4;
}

.listen {
    cursor:pointer;

    line-height:1rem;;
    padding:7px 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.125rem;
    color:#fff;
    vertical-align: middle;
    text-align: center;
}

.ripple {
    background-position: center;
    transition: background 0.8s;
  }
  .ripple:hover {
    background: #4285f4 radial-gradient(circle, transparent 1%, #6eb9f7 1%) center/15000%;
  }
  .ripple:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
