body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',sans-serif;
}

getcode {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}


html body main {
    height: 100% !important;
    background-color: black !important;
}

.help {
    text-align: left !important;
    font-size: 1.1em;
    position : absolute;
    left : 0;
    margin: 2%;
    z-index: 5000;
}

.infocode {
    color:white;
    z-index: 5000;
    margin-left: 5%;
    margin-right: 5%;
}

a {
    color:white !important;
    text-decoration: underline !important;
}
.mdb-progress {
    box-shadow: none;
    position: relative;
    display: flex;
    width: 100%;
    height: 4px;
    overflow: hidden;
    background-color: #eeeeee;
    
}

progress::-webkit-progress-bar {
    background-color: #eeeeee;
    
}

progress::-webkit-progress-value {
    background-color: orangered;
}

.progress .progress-bar {
    border-radius: 0;
    height: 4px;
    background-color: #C0C0C0; }

.dwnprogress {
    height:27px !important;
    float:left;
    color:rgba(247,244,228,0.5);
    font-size: 0.8em;

}

.butalbum {
    margin:1rem;
    height: 27px;
    /* font-size: 0.64rem; */
    /* text-transform: uppercase; */
    background-color:#4285f4;
}

.listen {
    cursor:pointer;

    line-height:1rem;;
    padding:7px 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.125rem;
    color:#fff;
    vertical-align: middle;
    text-align: center;
}

.ripple {
    background-position: center;
    -webkit-transition: background 0.8s;
    transition: background 0.8s;
  }
  .ripple:hover {
    background: #4285f4 -webkit-radial-gradient(circle, transparent 1%, #6eb9f7 1%) center/15000%;
    background: #4285f4 radial-gradient(circle, transparent 1%, #6eb9f7 1%) center/15000%;
  }
  .ripple:active {
    background-color: #6eb9f7;
    background-size: 100%;
    -webkit-transition: background 0s;
    transition: background 0s;
  }

body {
  background-color: black !important;
}

.bgWhite {
  background-color: #eee !important;
}
.elegant-color {
  background-color: #111111 !important;
}

.ui-center {
    text-align: center;
  }
  
  h1 {
    margin: 1.5em;
    font-size: 24px;
    text-align: center;
  }
  
  .carousel-container {
    position: relative;
    height: 350px;
    max-width: 1600px;
    margin: 0 auto;
    overflow: hidden;
    touch-action: pan-y;
    background-color: #222;
  }
  
  .carousel-track {
    display: flex;
    height: 100%;
  }
  
  .image-card {
    /* border: 2px solid white; */
  }

  .carousel-card {
    flex: 0 0 300px;
    height: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    

    -webkit-tap-highlight-color: transparent;
  }
  
  .carousel-card-inner {
    position: relative;
    flex-direction: column;
    width: 300px;
    height: 300px;
    border-radius: 5px;
  }
  
  .carousel-title {
    margin-top: -150px;
    font-size: 1.5em;
    text-align: center;
  }
  
  .carousel-text {
    padding: 1em;
    font-size: 14px;
    white-space: pre-wrap;
    text-align: left;
    font-family: sans-serif;
  }
p {
    font-size:16px !important;
}
ul {
    font-size:16px !important;
}

.instructions {
    padding-top:20px !important;
}
.App {
 /* text-align: center; */
}


.App-header {
  background-color: #222;
  height: 60px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

